<template>

  <b-card>
    <b-card-header class="pb-50">
      <b-card-title>Proxy List</b-card-title>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-success"
          class="btn-icon rounded-circle"
          @click="showModal(null)"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
    </b-card-header>
    <b-overlay
      :show="show"
      rounded="xl"
      :variant="variant"
      :opacity="opacity"
      :blur="blur"
    >
    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">
        <!-- sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(status)="data">
        <b-badge :variant="status[0][data.item.status]">
          {{status[1][data.item.status]}}
        </b-badge>
      </template>
      <template #cell(Expired)="data">
        <b-badge :variant="diffDate(data.item.uinfo.date_expired).alert">
          {{diffDate(data.item.uinfo.date_expired).diffDays}} days
        </b-badge>
      </template>
      <template #cell(email)="data">
        {{data.item.email}}
      </template>

      <template #cell(uid)="data">
        {{getEmail(data.item.uid)}}
      </template>
      
      <template #cell(bandwidth.total)="data">
        {{formatBytes(data.item.bandwidth.total)}}
      </template>
      <template #cell(bandwidth.used)="data">
        {{formatBytes(data.item.bandwidth.used)}}
      </template>
      <template #cell(date_expired)="data">
        <b-badge :variant="diffDate(data.item.date_expired).alert">
          {{diffDate(data.item.date_expired).diffDays}} days
        </b-badge>
      </template>
      <template #cell(_id)=data>
        <div class="text-nowrap">
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              :variant="data.item.status ? 'flat-danger':'flat-success'"
              class="btn-icon rounded-circle"
              @click="activeAccount(data.item._id)"
            >
              <feather-icon :icon="data.item.status ? 'LockIcon' : 'UnlockIcon'" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-warning"
              class="btn-icon rounded-circle"
              @click="showEditModal(data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="deleteAccount(data.item._id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
        </div>
        <div class="text-nowrap">
        
        </div>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    
     </b-overlay>
     <b-modal
      id="modal-edit-account"
      ok-title="Save"
      cancel-title="Cancel"
      size="lg"
      :title="'[EDIT] '+tempPost.name"
      @ok="onSubmit"
    >
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Name"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-form-input
                id="h-first-name"
                placeholder="Name"
                v-model="tempPost.name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Number Ports"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-form-select
                v-model="tempPost.sltype"
                :options="sltype"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Price"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-form-input
                id="h-first-name"
                placeholder="Price"
                v-model="tempPost.price"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Bandwidth"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-form-input
                id="h-first-name"
                placeholder="Price"
                v-model="tempPost.bandwidth.total"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Domain"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-form-input
                id="h-first-name"
                placeholder="Domain"
                v-model="tempPost.domain"
              />
            </b-form-group>
          </b-col>
           <b-col cols="12">
            <b-form-group
              label="Membership"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-form-input
                id="h-first-name"
                placeholder="Membership"
                v-model="tempPost.membership"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="API KEY"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-form-input
                id="h-first-name"
                placeholder="API KEY"
                v-model="tempPost.apikey"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Description"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-form-input
                id="h-first-name"
                placeholder="Description"
                v-model="tempPost.description"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="White IP"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-form-input
                id="h-first-name"
                placeholder="White IP"
                v-model="tempPost.ip"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Proxies"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-form-textarea
                id="h-first-name"
                placeholder="Proxies"
                v-model="tempPost.proxies"
                rows="10"
                max-rows="20"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-card>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BOverlay, BCard, BTooltip, BCardHeader, BCardTitle, BCol,BRow, BForm,
  BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import RepositoryFactory from '../../api/RepositoryFactory'
const PlansManagersRepository = RepositoryFactory.get('plansmanager')
const UserManagersRepository = RepositoryFactory.get('usermanagers')
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import plansRepository from '@/api/users/plansRepository'

const tempObj = {
  "_id": null,
  "sub_id": "",
  "date_expired": Date.now(),
  "status": 1,
  "name": "25 Port Proxy Resident",
  "domain": "zios.tools",
  "membership": "membership",
  "apikey": "token_is_here",
  "show": true,
  "description": "$300 (Coupon 50%) - Control GEO",
  "price": 300,
  "ip": "8.8.8.8",
  "proxies": "",
  "sltype": 25,
  "bandwidth":{
        total: 0,
        used: 0,
  },
  "__v": 0
}
export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BCard,
    BTooltip,
    BCardHeader,
    BCardTitle,
    BCol,BRow,
    BForm,
    BFormTextarea
  },
  data() {
    return {
      show:true,
      variant: 'dark',
      opacity: 0.85,
      sltype: [10,25,0],
      blur: '2px',
      perPage: 20,
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      userList: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'uid', label: 'User', sortable: true },
        { key: 'sltype', label: 'Total Port', sortable: true },
        { key: 'bandwidth.used', label: 'BW Used', sortable: true },
        { key: 'bandwidth.total', label: 'BW Cap', sortable: true },
        { key: 'membership', label: 'membership', sortable: true },
        { key: 'date_expired', label: 'date_expired', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: '_id', label: 'Action' },
      ],
      /* eslint-disable global-require */
      items:[],
      /* eslint-disable global-require */
      status: [
        {
          0: 'light-success', 1: 'light-primary', 2: "light-warning"
        },
        {
          0: 'Actived', 1: 'Empty', 2: "Proccessing"
        },
      ],
      tempPost: Object.assign({},tempObj)
    }
  },
  created(){
    this.show = true
    PlansManagersRepository.fetch().then(rs=>{
      this.items = rs.data.data  
      this.totalRows = this.items.length
    
      UserManagersRepository.fetch().then(rs=>{
        this.userList = rs.data.data
        this.items = this.items.map(x=>{
          x.email = this.getEmail(x.uid)
          x.username = this.getUserName(x.uid)
          return x
        })
        this.show = false
      }).catch(e=>{

      })
    }).catch(e=>{

    })
    
  },
  directives: {
    Ripple,
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    getEmail(id){
      if(this.userList.length > 0)
        return this.userList.find(x=>x._id === id) ? this.userList.find(x=>x._id === id).email : ""
      else  
        return ""
    },
    getUserName(id){
      if(this.userList.length > 0)
        return this.userList.find(x=>x._id === id) ? this.userList.find(x=>x._id === id).username : ""
      else  
        return ""
    },
    diffDate(d){
      var diffDays = parseInt((new Date(d) - Date.now()) / (1000 * 60 * 60 * 24), 10); 
      if(diffDays <= 0) diffDays = 0
      let alert = "";
      if(diffDays > 3)
        alert = "light-success"
      else 
        alert = "light-danger"

      return {
        diffDays,
        alert
      }
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      console.log(filteredItems)
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    showModal(id){
      this.tempPost = Object.assign({}, tempObj)
      this.$bvModal.show('modal-edit-account');
    },
    formatBytes(bytes) {
      var marker = 1000; // Change to 1000 if required
      var decimal = 2; // Change as required
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1024 KB
      var gigaBytes = marker * marker * marker; // One GB is 1024 MB
      var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
      // return bytes if less than a KB
      if(bytes < kiloBytes) return bytes + " Bytes";
      // return KB if less than a MB
      else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
      // return MB if less than a GB  ``
      else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
      // return GB if less than a TB
      else if(bytes < teraBytes) return (bytes / gigaBytes).toFixed(decimal) + " GB";
      
      else return (bytes / teraBytes).toFixed(decimal) + " TB";
    },
    showEditModal(item){
      //console.log(item)
      this.tempPost = Object.assign({}, item)

      this.tempPost.proxies = this.tempPost.proxies.join('\n')
      this.tempPost.ip = this.tempPost.ip.join(',')
      //console.log(JSON.stringify(this.tempPost))
      this.$bvModal.show('modal-edit-account');
    },
    onSubmit(event){
      event.preventDefault()
      if(this.tempPost._id){
        PlansManagersRepository.editAccount(this.tempPost._id,this.tempPost).then(rs=>{
          if(rs.data.success){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: "Edit success",
                variant: 'success',
              },
            })
          }else{
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: rs.data.dup.join(',')+' is duplicate',
                variant: 'danger',
              },
            })
          }
        })
      }else{
        PlansManagersRepository.addAccount(this.tempPost).then(rs=>{
          if(rs.data.success){
            this.items.push(rs.data.data)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: "Edit success",
                variant: 'success',
              },
            })
          }else{
            this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    text: rs.data.dup.join(',')+' is duplicate',
                    variant: 'danger',
                  },
              })
          }
          
        })
      }
      this.$bvModal.hide('modal-edit-account');
    },
    activeAccount(id){
      this.$bvModal
        .msgBoxConfirm('Are you sure?', {
          cancelVariant: 'outline-secondary',
        })
        .then(value => {
          if(value){
            PlansManagersRepository.setActive(id,{status: 0}).then(rs=>{
              this.items.find(x=>x._id == id).status = 0
            })
          }
        })
    },
    deleteAccount(id){
     this.$bvModal
        .msgBoxConfirm('Are you sure?', {
          cancelVariant: 'outline-secondary',
        })
        .then(value => {
          if(value){
            PlansManagersRepository.deleteAccount(id).then(rs=>{
              this.items = this.items.filter(x=>x._id != id)
            })
          }
        })
    }
  },
  
}
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-select.scss';
.dark-layout {
  div ::v-deep .card .card-body {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>